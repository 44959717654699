
















































































































import { Component, Vue } from 'vue-property-decorator';
import { ApiService, IReferralAppLeaderboardResponse, IReferralAppLeaderboardUser } from '@/services/ApiService';
import { LeaderboardType } from '@/types/Leaderboard';
import InviteError from '@/components/invite/InviteError.vue';
import Paginator from '@/components/pagination/Paginator.vue';

interface ILeaderboardFilterQuery {
	page: number,
	limit: number,
	pageSizes: number[]
}

@Component({
	name: 'Leaderboard',
	components: { InviteError, Paginator }
})
export default class extends Vue {
	private loading = false;
	private hasError = false;
	private showPrizeDropDescription = true;
	private LeaderboardType = LeaderboardType;
	private leaderboardType = LeaderboardType.TotalLoyaltyPoints;
	private paginationLayout = 'prev, pager, next';
	private total = 0;
	private listQuery: ILeaderboardFilterQuery = {
		page: 1,
		limit: this.$store.state.paginationInfo.leaderboardPageSize,
		pageSizes: [
			this.$store.state.paginationInfo.leaderboardPageSize,
			2 * this.$store.state.paginationInfo.leaderboardPageSize,
			3 * this.$store.state.paginationInfo.leaderboardPageSize
		]
	};

	private leaderboardData: IReferralAppLeaderboardUser[] = [];

	created (): void {
		this.getLeaderboard();
	}

	private async getLeaderboard (): Promise<void> {
		try {
			this.clearErrorFlags();
			this.loading = true;
			const offset = (this.listQuery.page - 1) * this.listQuery.limit;
			const response: IReferralAppLeaderboardResponse = await ApiService.getLeaderboard(this.leaderboardType, offset, this.listQuery.limit);

			this.leaderboardData = response.top;
			this.total = response.total;
			this.loading = false;
		} catch (err) {
			this.loading = false;
			this.setErrorFlag(err as string);
		}
	}

	/**
	 * Method to set the appropriate error flag.
	 */
	private setErrorFlag (_errMessage: string): void {
		this.hasError = true;
	}

	/**
	 * Method to clear (reset) any error flags.
	 */
	private clearErrorFlags (): void {
		this.hasError = false;
	}

	private handleGetLeaderboard (type: LeaderboardType): void {
		this.leaderboardType = type;
		this.listQuery.page = 1;
		this.listQuery.limit = this.$store.state.paginationInfo.leaderboardPageSize;
		this.getLeaderboard();
	}
}

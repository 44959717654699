import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		googlePlayStoreLink: 'https://play.google.com/store/apps/details?id=com.exponentialcorp.tallyup',
		appleAppStoreLink: 'https://apps.apple.com/us/app/tallyup-everybody-wins/id1508025200',
		gitbookLink: 'https://tallyup.gitbook.io/',
		paginationInfo: {
			leaderboardPageOffset: 0,
			leaderboardPageSize: 10
		}
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
});

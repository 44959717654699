import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Invite from '@/views/Invite.vue';
import Leaderboard from '@/views/Leaderboard.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Invite,
		children: [{
			path: '/:inviteCode',
			name: 'InviteMain',
			props: true
		}]
	},
	{
		path: '/leaderboard',
		name: 'Leaderboard',
		component: Leaderboard,
		redirect: '/leaderboard/gems',
		children: [
			{
				path: 'gems',
				component: Leaderboard,
				name: 'Gems'
			}
		]
	},
	{
		path: '*',
		redirect: '/'
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { scrollTo } from '@/utils/scrollTo';

@Component({
	name: 'Paginator'
})
export default class extends Vue {
	@Prop({ required: true }) private total!: number
	@Prop({ default: false }) private small!: number
	@Prop({ default: 1 }) private page!: number
	@Prop({ default: 20 }) private limit!: number
	@Prop({ default: () => [10, 20, 30, 50] }) private pageSizes!: number[]
	@Prop({ default: 5 }) private pagerCount!: number[]
	@Prop({ default: 'total, sizes, prev, pager, next, jumper' }) private layout!: string
	@Prop({ default: true }) private background!: boolean
	@Prop({ default: true }) private autoScroll!: boolean
	@Prop({ default: false }) private hidden!: boolean
	@Prop({ default: false }) private disabled!: boolean

	get currentPage (): number {
		return this.page;
	}

	set currentPage (value: number) {
		this.$emit('update:page', value);
	}

	get pageSize (): number {
		return this.limit;
	}

	set pageSize (value: number) {
		this.$emit('update:limit', value);
	}

	handleSizeChange (value: number): void {
		this.$emit('pagination', { page: this.currentPage, limit: value });
		if (this.autoScroll) {
			scrollTo(0, 800);
		}
	}

	handleCurrentChange (value: number): void {
		this.$emit('pagination', { page: value, limit: this.pageSize });
		if (this.autoScroll) {
			scrollTo(0, 800);
		}
	}
}

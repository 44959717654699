function easeInOutQuad (t: number, b: number, c: number, d: number) {
	t /= d / 2;
	if (t < 1) {
		return c / 2 * t * t + b;
	}
	t--;
	return -c / 2 * (t * (t - 2) - 1) + b;
}

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (function () {
	return window.requestAnimationFrame || window.webkitRequestAnimationFrame || (window as any).mozRequestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 60); };
})();

// It is difficult to detect the scrolling element, so just move them all
function move (amount: number) {
	document.documentElement.scrollTop = amount;
	(document.body.parentNode as HTMLElement).scrollTop = amount;
	document.body.scrollTop = amount;
}

function position () {
	return document.documentElement.scrollTop || (document.body.parentNode as HTMLElement).scrollTop || document.body.scrollTop;
}

/**
 * Method that handles the scrolling animation of the document element to the provided position.
 * The scrolling animation occurs in the provided duration.
 * @param {number} to The position (in pixels) to scroll to.
 * @param {number} duration The time (in milliseconds) that the scrolling animation should take to complete.
 * @param {Function} callback Optional method to be executed once the scrolling animation has completed.
 */
export function scrollTo (to: number, duration: number, callback?: Function): void {
	const start = position();
	const change = to - start;
	const increment = 20;
	let currentTime = 0;
	duration = (typeof (duration) === 'undefined') ? 500 : duration;
	const animateScroll = function () {
		// increment the time
		currentTime += increment;
		// find the value with the quadratic in-out easing function
		const val = easeInOutQuad(currentTime, start, change, duration);
		// move the document.body
		move(val);
		// do the animation unless its over
		if (currentTime < duration) {
			requestAnimFrame(animateScroll);
		} else {
			if (callback && typeof (callback) === 'function') {
			// the animation is done so lets callback
				callback();
			}
		}
	};
	animateScroll();
}

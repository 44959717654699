















import { Component, Vue } from 'vue-property-decorator';
import InviteError from '@/components/invite/InviteError.vue';
import InviteMain from '@/components/invite/InviteMain.vue';

@Component({
	components: { InviteError, InviteMain },
	name: 'Invite'
})
export default class extends Vue {
	selectedComponent = 'invite-main'

	created (): void {
		if (this.$route.path === '/') {
			this.selectedComponent = 'invite-error';
		}

		if (this.$route.path === '/leaderboard') {
			this.$router.push('/leaderboard/gems');
		}
	}
}

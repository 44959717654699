








import { Vue, Component } from 'vue-property-decorator';

@Component({
	name: 'InviteError'
})
export default class extends Vue {
}

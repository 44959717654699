import axios, { AxiosError } from 'axios';
import store from '@/store';
import { LeaderboardType } from '@/types/Leaderboard';

function getErrorMessage (err: AxiosError): string {
	if (err.response && err.response.data && err.response.data.error && err.response.data.error.msg) {
		return err.response.data.error.msg;
	}

	return 'Something went wrong';
}

export interface IAddReferrerResponse {
	phone: string;
	appStoreLink: string;
}

export interface IReferrerResponse {
	username: string;
	profilePicture: string|null;
}

/** Item containing the links to the previous and next pages */
export interface IReferralAppPaginatedLeaderboardLinks {
	prev?: string;
	next?: string;
}

export interface IReferralAppLeaderboardUser {
	username: string;
	pictureURL: string;
	rank: number;
	score: number;
}

export interface IReferralAppLeaderboardResponse {
	top: IReferralAppLeaderboardUser[],
	total: 	number;
	offset:	number;
	limit:	number;
	links: IReferralAppPaginatedLeaderboardLinks[];
}

export class ApiService {
	static async findReferrer (inviteCode: string): Promise<IReferrerResponse> {
		try {
			const result = await axios.get(`${process.env.VUE_APP_BASE_API}/referrer?inviteCode=${inviteCode}`);
			return result.data.data;
		} catch (err) {
			throw getErrorMessage(err as AxiosError);
		}
	}

	static async addReferrer (phone: string, referrerUsername: string, deviceOS: string): Promise<IAddReferrerResponse> {
		try {
			const postParams = {
				phone,
				referrerUsername,
				deviceOS
			};

			const results = await axios.post(`${process.env.VUE_APP_BASE_API}/referrer`, postParams);

			return results.data.data;
		} catch (err) {
			throw getErrorMessage(err as AxiosError);
		}
	}

	static async getLeaderboard (type: LeaderboardType, offset?: number, limit?: number): Promise<IReferralAppLeaderboardResponse> {
		try {
			const queryParams = {
				type,
				offset: (offset != null) ? encodeURIComponent(offset) : store.state.paginationInfo.leaderboardPageOffset,
				limit: (limit != null) ? encodeURIComponent(limit) : store.state.paginationInfo.leaderboardPageSize
			};

			const results = await axios.get(`${process.env.VUE_APP_BASE_API}/referrer/leaderboard`, { params: queryParams });
			return results.data.data;
		} catch (err) {
			throw getErrorMessage(err as AxiosError);
		}
	}
}



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import InviteError from '@/components/invite/InviteError.vue';
import { ApiService, IReferrerResponse } from '@/services/ApiService';

enum ApiErrors {
	ReferrerNotFound = 'Referrer not found for the provided invite code'
}

@Component({
	components: { InviteError },
	name: 'InviteMain'
})
export default class extends Vue {
	@Prop({ required: true }) private inviteCode!: string;
	private referrerUsername = '';
	private referrerProfilePicture: string|null = null;
	private buttonText = 'COPY TO CLIPBOARD';
	private copiedButtonText = 'USERNAME COPIED TO CLIPBOARD!';
	private googlePlayStoreLink = 'https://play.google.com/store/apps/details?id=com.exponentialcorp.tallyup';
	private appleAppStoreLink = 'https://apps.apple.com/us/app/tallyup-everybody-wins/id1508025200';
	private loading = false;
	private doesReferrerExist = true;
	private apiErrors = ApiErrors;
	private hasError = false;

	created (): void {
		// Fetch the referrer information.
		this.findReferrer();
	}

	/**
	 * Method to copy the referrer username to the clipboard.
	 */
	private copyTextToClipboard (): void {
		const button = document.getElementById('invite-button-clipboard');
		if (button) {
			// Change button text and opcaity
			button.innerText = this.copiedButtonText;
			button.style.opacity = '0.8';
			// Copy the referrer username to the clipboard
			navigator.clipboard.writeText(this.referrerUsername);
			// Revert the button text and opacity back to the original values after 2 secs
			window.setTimeout(() => {
				button.innerText = this.buttonText;
				button.style.opacity = '1';
			}, 2000);
		}
	}

	/**
	 * Fetch the referrer information.
	 */
	private async findReferrer (): Promise<void> {
		try {
			this.clearErrorFlags();
			this.loading = true;

			const response: IReferrerResponse = await ApiService.findReferrer(this.inviteCode);
			this.loading = false;

			if (response.username) {
				this.referrerUsername = response.username;
				this.referrerProfilePicture = response.profilePicture;
				this.buttonText = `COPY ${response.username.toUpperCase()} TO CLIPBOARD`;
				this.doesReferrerExist = true;
			}
		} catch (err) {
			this.loading = false;
			this.setErrorFlag(err as string);
		}
	}

	/**
	 * Method to set the appropriate error flag.
	 */
	private setErrorFlag (errMessage: string): void {
		switch (errMessage) {
		case this.apiErrors.ReferrerNotFound:
			this.doesReferrerExist = false;
			break;
		default:
			this.hasError = true;
		}
	}

	/**
	 * Method to clear (reset) any error flags.
	 */
	private clearErrorFlags (): void {
		this.hasError = false;
	}
}
